<template>
  <v-container class="mt-4 ml-2">

	  <v-row justify="center">
	    <v-col cols="12">
	    	<v-card class="shadowCard">
	    	  <v-card-title class="text-subtitle-1">
	    	    Cargar archivo del conmutador
	    	    <v-spacer></v-spacer>
            <upload-excel-component :on-success="handleSuccess" :before-upload="beforeUpload" class="mr-2"/>
	        	<v-btn  
	        		v-if="tableData.length"
	        		color="success" 
	        		dark  
	        		small
	        		class="ml-4"
	            @click="procesarInformacion()"
	          > Guardar </v-btn> 
	    	  </v-card-title>

	        <v-col cols="12">
	          <v-card flat>
	            <v-alert outlined type="warning"  prominent v-if="!tableData.length && !reportes.length">
	              NO SE HA CARGADO NINGUN DOCUMENTO.
	            </v-alert>

	            <v-row justify="end">
	            	<v-col cols="12" md="10" lg="6">
			            <v-text-field
			              v-model="search"
			              dense
			              filled
			              label="Buscar"
			              append-icon="mdi-magnify"
			            ></v-text-field>
	            	</v-col>
	            </v-row>

	            <v-card v-if="tableData.length">
	              <v-data-table
	                dense
	                :headers="tableHeader"
	                :items="tableData"
	                item-key="name"
	                :page.sync="page"
							    @page-count="pageCount = $event"
	                fixed-header
	                hide-default-footer
	                :height="tamanioPantalla"
                  locale="es"
	              	:search="search"
	              >
	              </v-data-table>
	            </v-card>
	            <div class="text-center pt-2" v-if="tableData.length">
	              <v-pagination v-model="page" :length="pageCount"></v-pagination>
	            </div>
	          </v-card>
	        </v-col>
	    	</v-card>
	    </v-col>
	  </v-row>

     <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>
	</v-container>
</template>

<script>
import axios from 'axios';
  import UploadExcelComponent from '@/components/UploadExcel.vue'

	import { mapGetters} from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'
  import filtrosRolTeachers    from '@/mixins/filtrosRolTeachers'


  export default {

  	components:{
      Alerta,
      carga,
    	UploadExcelComponent
    },

    mixins: [ validarErrores, funcionesExcel, filtrosRolTeachers ],

	  data() {
	    return {
	    	// Alertas
	    	parametros:{
	        dialogError: false,
	        mensaje: '',
	        color: ''
	      },

	      respuestaAlerta:false,
	      loader: true,
	      cargar: false,

	      page: 1,
	      pageCount: 0,
	      itemsPerPage: 20,

	      search:'',

	      tableHeader: [ 
		      { text: 'account code'              , value: 'account_code' },
					{ text: 'caller number'             , value: 'caller_number' },
					{ text: 'callee number'             , value: 'callee_number'},
					{ text: 'context'                   , value: 'context'},
					{ text: 'calerid'                   , value: 'calerid'},
					{ text: 'start time'                , value: 'start_time'},
					{ text: 'answer time'               , value: 'answer_time'},
					{ text: 'end time'                  , value: 'end_time'},
					{ text: 'call time'                 , value: 'call_time'},
					{ text: 'talk time'                 , value: 'talk_time'},
					{ text: 'disposition'               , value: 'disposition'},
					{ text: 'userfield'                 , value: 'userfield'},
					{ text: 'dest channel extension'    , value: 'dest_channel_extension'},
					{ text: 'caller name'               , value: 'caller_name'},
					{ text: 'answer by'                 , value: 'answer_by'},
					{ text: 'uniqueid'                  , value: 'uniqueid'}
	      ],   

	      tableData: [],  // DATOS A MOSTRAR 
	      reportes : [],  // TABLA REPORTES

	      alerta: { activo: false, texto:'', color:'error'},
	      overlay: false,
	      
	      codigos:[]
	    }
	  },

	  async created(){
	  },

	  computed:{
	    ...mapGetters('login',['getdatosUsuario']),
	    tamanioPantalla () {
	      return this.$vuetify.breakpoint.height -380
	    },
	  },

	  methods: {

	    beforeUpload(file) {
	      const isLt1M = file.size / 1024 / 1024 < 1
	      if (isLt1M) {
	        return true
	      }
	      this.$message({
	        message: 'Please do not upload files larger than 1m in size.',
	        type: 'warning'
	      })

	      return false
	    },

	    handleSuccess({results, header }) {
	      this.obtenerDatos(results);
	    },

	    obtenerDatos (results){
	      var me = this
	      let Temporal  =  results.map(obtenerValoresPresupuesto)
	      let value2 = {}
	      function obtenerValoresPresupuesto(value, index, array) {
	        if (value['account code'] !== null ) {
	          value.account_code = value['account code']
	        }

	        if (value['caller number'] !== null ) {
	          value.caller_number = value['caller number']
	        }  

	        if (value['callee number'] !== null ) {
	          value.callee_number = value['callee number']
	        }
	        
	        if (value['context'] !== null ) {
	          value.context = value['context']
	        }

	        if (value['calerid'] !== null ) {
	          value.calerid = value['calerid']
	        }

	        if (value['start time'] !== null || value['start time'] !== '' ) {
	          value.start_time = me.excelDateToJSDate( value['start time'] )
	        }

	        if (value['answer time'] !== null || value['answer time'] !== '') {
	          value.answer_time = me.excelDateToJSDate( value['answer time'] )
	        }

	        if (value['end time'] !== null ) {
	          value.end_time = me.excelDateToJSDate( value['end time'] )
	        }

	        if (value['call time'] !== null ) {
	          value.call_time = value['call time']
	        }

	        if (value['talk time'] !== null ) {
	          value.talk_time = value['talk time']
	        }

	        if (value['disposition'] !== null ) {
	          value.disposition = value['disposition']
	        }

	        if (value['userfield'] !== null ) {
	          value.userfield = value['userfield']
	        }

	        if (value['dest channel extension'] !== null ) {
	          value.dest_channel_extension = value['dest channel extension']
	        }

	        if (value['caller name'] !== null ) {
	          value.caller_name = value['caller name']
	        }

	        if (value['answer by'] !== null ) {
	          value.answer_by = value['answer by']
	        }

	        if (value['uniqueid'] !== null ) {
	          value.uniqueid = value['uniqueid']
	        }

	        return value 
	      }

	      this.tableData = Temporal
	    },

	    excelDateToJSDate(serial) {

	    	if( serial ){

	    		/*
						Calcular la fecha
	    		*/
				  var utc_days  = Math.floor(serial - (25567 + 1));
				  var utc_value = utc_days * 86400;                                        
				  var date_info = new Date(utc_value * 1000 );

				  const fecha2 = (new Date(date_info - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
				  const desgloseFecha = fecha2.split('-')

				  /*
						CALCULAR LA HORA
				  */
				  var fractional_day = serial - Math.floor(serial) + 0.0000001;
				  var total_seconds = Math.floor(86400 * fractional_day);
				  var seconds = total_seconds % 60;
				  total_seconds -= seconds;

				  var hours = Math.floor(total_seconds / (60 * 60));
				  var minutes = Math.floor(total_seconds / 60) % 60;

				  let fecha = (desgloseFecha[0] +'-'+ desgloseFecha[1] +'-'+  desgloseFecha[2] +' '+ hours +':'+ (minutes < 10 ? ('0' + minutes) : minutes)) +':'+  (seconds < 10 ? ('0' + seconds) : seconds)

			  	return fecha
	    	}

			  return null
			},

	    procesarInformacion( ){
	    	this.cargar = true
    		this.$http.post('conmutador.cargar.datos', this.tableData ).then(response=>{
    			this.tableData = []
    			this.validarSuccess( response.data.message )
    			this.cargar = false
    		}).catch(error=>{ 
    			this.validarError( error.response.data.message )
    		}).finally( () =>{ this.cargar = false } )
	    },

	  }
	}
</script>